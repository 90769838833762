.main-btn {
  height: 44px;
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  position: relative;

  &:disabled {
    cursor: default;
    filter: grayscale(10);
  }

  & span {
    z-index: 1;
    position: relative;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    svg {
      width: 100%;
      display: block;
      height: 100%;
    }
  }
}
