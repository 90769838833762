*, ::after, ::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}
  
body, html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
  
body {
  position: relative;
  font-size: 15px;
  min-width: 320px;
  font-variant-ligatures: none;
}
  
h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}
  
ul, li {
  display: block;
  padding: 0px;
  margin: 0px;
}
  
textarea {
  resize: none;
}
  
p {
  font-size: 15px;
}
  
img {
  border-style: none;
}

button {
  border: 0;
  padding: 0;
  background: transparent;
}
  
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
  
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
  
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: none;
}
  
[type="checkbox"],
[type="radio"] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
  
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
  
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}
  
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
  
input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
  
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoratio {
  display: none;
}
  
[hidden] {
  display: none;
}
  
p, nav {
  margin: 0;
  padding: 0;
}
  
a, a:hover, a:active, a:focus,
button, input, button:hover, button:active, button:focus,
input:focus, input:active {
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.5s;
  -webkit-transition-timing-function: ease;
}
  
input {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
  
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
  
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}