// header

@import "../../assets/vendor/_config";
@import "../../assets/vendor/_vars";

.resources-popup {
  width: 98%;
  position: absolute;
  max-width: 576px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 15px;
  padding-bottom: 31px;
  border-radius: 2px;
  border: 1px solid $accent-color;
  background: url("../../assets/img/popup-bg.jpg") no-repeat center top / cover;

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    max-width: 576px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000c5;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: transparent;
      border: 6px solid $accent-color;
      border-top-color: #ffffff00;
      animation: loader 10s linear infinite;
    }
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__figure {
    width: 100%;
    svg {
      display: block;
      width: 100%;
    }
  }
  &__wrap {
    gap: 9px;
    margin-top: 11px;
  }
  &__more {
    display: flex;
    justify-content: center;
    margin-top: 9px;
    pointer-events: none;
    svg {
      display: block;
    }
  }
  &__nav {
    margin-top: -10px;
    padding: 0px 12px;
  }
  &__inner {
    margin-top: 18px;
    padding: 0px 25px;
  }
  &__banner {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    & div {
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    svg {
      width: 100%;
      display: block;
      height: 100%;
    }
  }

  &__and {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    color: #fff;
    flex-shrink: 0;
    margin: 0px 6px;
  }
  &__points {
    img {
      display: block;
      width: 18px;
      flex-shrink: 0;
    }
    &-item {
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__value {
    position: relative;
    height: 34px;
    min-width: 86px;

    padding-inline: 8px;
    z-index: 1;
    flex-shrink: 0;
    &-content {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      img {
        display: block;
        flex-shrink: 0;
        width: 29px;
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@keyframes loader {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 3600deg;
  }
}
