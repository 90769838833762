@import "../../../assets/vendor/vars";

.main-mine-block {
  margin-top: 42px;
  &__params {
    width: 349px;
    margin: 0 auto;
    position: relative;
  }

  &__params-head {
    transform: translateY(-13px);
  }
  &__params-point {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    text-shadow: 3px 5px 3px rgba(0, 0, 0, 0.25);
    letter-spacing: 0.3px;
    font-family: "Orbitron", sans-serif;
  }
  &__params-wrap {
    margin-top: -3px;
  }
  &__params-value {
    width: 140px;
    text-align: center;
    // height: 35px;
    font-size: 18px;
    font-style: normal;
    text-shadow: 0px 0px 26px #060057;
    font-weight: 600;
    line-height: 35px;
    text-transform: uppercase;
    white-space: nowrap;
  }
  &__params-value_time {
    z-index: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    transform: translateY(1px);
    // line-height: normal;
    color: #a0a5ff;
    letter-spacing: 0.48px;
    font-family: "Orbitron", sans-serif;
  }
  &__params-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    svg {
      display: block;
      width: 100%;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
  }
  &__btn-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    text-shadow: 0px 0px 26px #060057;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-transform: uppercase;
  }
  &__btn-bg {
    svg {
      display: block;
    }
  }
  &__time {
    width: 350px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 6px;
  }
  &__time-bg {
    svg {
      display: block;
    }
  }
  &__time-value {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.36px;
    font-family: "Orbitron";
    display: block;
    position: absolute;
    top: 5px;
    left: 9px;
    line-height: 16px;
  }
  &__time-item {
    position: relative;

    &.hideen {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__time-item:nth-child(1) &__time-value {
    color: #e08aff;
  }
  &__time-item:nth-child(2) &__time-value {
    color: #7de2a8;
    left: unset;
    right: 9px;
  }
  &__control {
    margin: 0 auto;
    margin-top: 27px;
    position: relative;
  }
  &__control-inner {
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, auto);
    column-gap: 2px;
    justify-content: center;
  }
  &__nav {
    overflow: hidden;
    cursor: pointer;
    z-index: 1;

    &:nth-child(1) {
      border-top-left-radius: 100%;
      transform: translateY(-2.5px);
    }
    &:nth-child(2) {
      border-top-right-radius: 100%;
    }
    &:nth-child(3) {
      border-bottom-left-radius: 100%;
    }

    &:nth-child(4) {
      border-bottom-right-radius: 100%;
      transform: translateY(2.5px);
    }

    &:disabled {
      cursor: default;
    }
  }
  &__nav-btn {
    svg {
      display: block;
    }
  }
}
