.image-puzzle-player {
  display: flex;
  align-items: center;
  gap: 3px;

  &__btn {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 2px solid #201127;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &.playing,
    &:active {
      background-color: #201127;
    }

    &.next svg {
      transform: rotate(180deg);
    }
  }
}
