@import "../../../assets/vendor/vars";

.tasks-list {
  margin-top: 16px;
  &__content-item {
    display: block;
  }

  &__item {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__lvl {
    padding: 3px 8px;
    background: rgba(120, 34, 151, 0.5);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 0;
    margin-left: 5px;
    line-height: 12px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
  &__banner {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  &__count {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: normal;
    }
  }
  &__tag {
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 8.5px 10px;
    position: relative;
    z-index: 1;
  }
  &__tag-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__card {
    background: #1c1632;
  }
  &__main {
    padding: 11px 10px;
  }
  &__text {
    margin-top: 10px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  &__offer {
    margin-top: 8px;
    .tasks-list__reward {
      min-width: 82px;
      width: fit-content;
      padding-inline: 4px;
    }
  }
  &__btns {
    gap: 8px;
  }
  .tasks-list__btn {
    width: 91px;
    height: 32px;
  }
  &__reward {
    gap: 6px;
    font-size: 12px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 1;
    padding: 2px 0px;
    img {
      display: block;
      flex-shrink: 0;
      width: 19px;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__btn {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    padding: 10px 0px;
    color: #fff;
    position: relative;
    z-index: 1;
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__daily {
    padding: 8px;
    gap: 12px;
    &-info {
      width: 91px;
      flex-shrink: 0;
    }
    .tasks-list__reward {
      margin-bottom: 6px;
    }
    &-date {
      z-index: 1;
      position: relative;
      width: 76px;
      text-align: center;
      height: 64px;
      flex-shrink: 0;
      padding-top: 5px;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &-title {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-value {
      margin-top: 6px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    color: $accent-color;
    flex: 1;
  }

  &__timer {
    font-size: 11px;
    font-weight: 400;
    font-family: "Orbitron", sans-serif;
    color: #d947ab;
    text-align: center;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item-timer {
    font-size: 11px;
    font-weight: 400;
    color: #d947ab;
    font-family: "Orbitron", sans-serif;
  }

  &__traffy-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;

    .traffy-custom {
      --traffy-buttonRewardImage-background-image: url("../../../assets/img/stats-dark.png");
      --traffy-buttonRewardImage-background-size: 16px;
      --traffy-taskElementCont-height: 70px;
      --traffy-taskElementInfoCont-height: auto;
      --traffy-taskElementChannelText-color: #7f5cff;
      --traffy-taskElementCont-border-radius: 0;
      --traffy-taskElementButtonCheckText-font-size: 11px;
      --traffy-taskElementButtonCheckText-font-weight: 600;

      --traffy-buttonCheckRewardImage-background-image: none;
      --traffy-buttonCheckRewardImage-background-size: 0;

      --traffy-taskElementButtonText-padding-left: 4px;

      --traffy-taskElementImageCont-display: block;
      --traffy-taskElementInfoCont-gap: 10px;

      --traffy-taskElementInstructionCont-width: 150px;
    }

    .traffy-taskElementCont {
      width: 100%;
      background-color: #1c1731;
    }

    .traffy-taskElementInstructionText {
      color: #fff;
    }

    .traffy-taskElementButtonContOuter {
      background-color: rgb(127, 92, 255);
    }

    .traffy-taskElementButtonContOuter {
      background: url("../../../assets/img/btnBg.png");
      background-size: contain;
      width: 90px;
      height: 32px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  &__adsgram-card {
    --adsgram-task-button-width: 90px;
  }

  &__adsgram-income {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 4px;
  }


  &__adsgram-income-img {
    width: 14px;
    height: 14px;
  }
}
