@import "../../../assets/vendor/vars";

.image-puzzle-awards {
  margin-top: 2px;
  border: 1px solid #7957f1;
  background: #211129;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-inline: 14px;
  margin-inline: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title {
    font-size: 12px;
    font-weight: 400;
    font-family: "BOWLER";
    color: #7f5cff;
    margin-bottom: 17px;
    text-align: center;
  }

  &__list {
    max-width: 274px;
    list-style: none;
  }

  &__list-item {
    list-style: none;
    font-size: 14px;
    font-weight: 300;
    color: #7f5cff;
    font-family: "HausSquarePro";
  }

  &__btn {
    max-width: 133px;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-family: "HausSquarePro";
    font-weight: 300;
    color: $accent-color;
    border: 1px solid $accent-color;
    margin-top: 20px;
  }
}
