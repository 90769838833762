@import "../../../assets/vendor/_config";
@import "../../../assets/vendor/_vars";

.select {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0px;
  transition: all 0.3s;

  &_opened {
    gap: 20px;
  }

  &__dropdownBtn {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;

    border: none;
    background: transparent;
    padding: 8px 10px;
    font-size: 18px;
    font-weight: 500;
    position: relative;
    color: $white-color;
    width: 100%;
  }

  &__dropdownBtnBg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    fill: $accent-color;
  }

  &__dropdownBtnText {
    padding-left: 8px;
    z-index: 1;

    &_empty {
      color: #87858f;
    }
  }

  &__dropdownBtnArrow {
    z-index: 1;
    transition: all 0.3s;

    &_active {
      transform: rotate(180deg);
    }
  }

  &__valueItems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  &__valueItem {
    background: #ffffff14;
    padding: 8px 12px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  &__valueItemTxt {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
  }
  &__valueItemCrossBtn {
    cursor: pointer;
    border: none;
    background: transparent;
  }
  &__valueItemCrossIcon {
    width: 10px;
    height: 10px;
  }

  &__dropdownContent {
    width: 100%;
    background: #1c1731;
    border: 1px solid $accent-color;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__dropdownContentItem {
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.3s;

    &:hover,
    &:focus,
    &:focus-visible {
      background: #2a2a2a;
      outline: none;
    }
  }

  &__dropdownContentItem:not(:last-child) &__dropdownContentItemTxt {
    border-bottom: 0.5px solid $accent-color;
  }

  &__dropdownContentItemTxt {
    padding-inline: 16px;
    padding-block: 12px;
    text-align: left;
    display: block;
    width: 100%;
    font-size: 16px;
    color: $white-color;
  }

  &__dropdownContentCheckicon {
    position: absolute;
    right: 18px;
  }
}
