@import "../../../assets/vendor/_config";
@import "../../../assets/vendor/_vars";

.witdraw-form {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;

  &__title {
    font-size: 13px;
    font-weight: 600;
    color: $accent-color;
    text-align: center;
    margin-bottom: 20px;
  }

  &__select {
    margin-bottom: 20px;
  }

  &__set-all-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 12px;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  &__commission-text {
    font-size: 12px;
    font-weight: 500;
    color: $white-color;

    &.invalid {
      color: #941414;
      transition: all 0.3s;
    }
  }
  &__see-all-btn {
    border: 0;
    position: relative;
    padding-inline: 6px;
    min-width: 122px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    color: $white-color;

    & span {
      z-index: 1;
    }
  }

  &__see-all-btn-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__submit-btn {
    max-width: 174px;
    margin-top: 30px;
    margin-inline: auto;

    &:disabled {
      filter: grayscale(1);
    }
  }
}
