@import "../../../assets/vendor/vars";

.friends-main {
  &__title {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: $accent-color;
    text-transform: uppercase;
  }
  &__earn {
    margin-top: 12px;
    padding: 8px;
    padding-bottom: 20px;
    padding-left: 25px;
    position: relative;
    z-index: 1;
    gap: 30px;
  }
  &__earn-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__earn-all {
    flex-shrink: 0;
    &-title {
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: rgba(255, 255, 255, 0.8);
    }
  }
  &__earn-btn {
    height: 38px;
    width: 100%;
    position: relative;
    z-index: 1;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #fff;
    transition: all 0.3s;

    &:disabled {
      cursor: default;
      filter: grayscale(10);
    }
  }
  &__earn-btn-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__earn-res {
    margin-top: 5px;
    gap: 12px;
  }
  &__earn-res-icon {
    width: 19px;
    height: 19px;
    overflow: hidden;
    flex-shrink: 0;
    margin-right: 4px;
  }
  &__earn-res-value {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &__status {
    margin-top: 16px;
  }
  &__status-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__status-figure {
    position: absolute;
    right: 58px;
    top: 0;
    transform: translateY(-50%);
    z-index: 2;
    svg {
      display: block;
    }
  }
  &__status-item {
    position: relative;
    z-index: 1;
    padding: 15px;
    transform: translateY(-1px);
    &:first-child {
      transform: none;
      .friends-main__status-figure {
        display: none;
      }
    }
  }
  &__status-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
  }
  &__status-value {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $accent-color;
  }
  &__status-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__status-figure {
    position: absolute;
    right: 58px;
    top: 0;
    transform: translateY(-50%);
    z-index: 2;
    svg {
      display: block;
    }
  }
  &__status-item {
    position: relative;
    z-index: 1;
    padding: 15px;
    transform: translateY(-1px);
    &:first-child {
      transform: none;
      .friends-main__status-figure {
        display: none;
      }
    }
  }
  &__status-title {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #fff;
  }
  &__value {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $accent-color;
  }
  &__nav {
    margin-top: 20px;
    gap: 9px;
  }

  &__nav-offer {
    svg {
      display: block;
      flex-shrink: 0;
      margin-right: 6px;
    }
  }
}
