// header

@import "../../assets/vendor/_config";
@import "../../assets/vendor/_vars";

.settings-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
  }
  &__body {
    width: 98%;
    position: absolute;
    max-width: 576px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 15px;
    padding-bottom: 31px;
    border-radius: 2px;
    border: 1px solid $accent-color;
    background: url("../../assets/img/popup-bg.jpg") no-repeat center top /
      cover;
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &__figure {
    width: 100%;
    svg {
      display: block;
      width: 100%;
    }
  }

  &__main {
    max-width: 240px;
    margin-top: 14px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  &__wrap {
    gap: 9px;
    margin-top: 11px;
  }

  &__language {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  &__item {
    font-size: 14px;
    display: block;
    color: $white-color;
    font-weight: 600;
    width: fit-content;
  }

  &__btnItem {
    margin: 0 auto;
  } 
  &__languages-list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }
  &__language-list-item {
    background: #2a1e54;
    border: 1px solid rgba(127, 92, 255, 0.5);
    padding: 4px 6px;
    transform: all 0.3s;
    color: $white-color;

    &.active {
      background-color: $accent-color;
    }
  }
}
