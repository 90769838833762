@import "../../assets/vendor/vars";

.error-desk {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-inline: 10px;
  gap: 20px;

  &__text {
    font-size: 18px;
    color: $white-color;
    text-align: center;
    padding-inline: 20px;
  }

  &__btn {
    max-width: 300px;
  }
}
