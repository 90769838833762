

// mobile

.mobile {
    &-nav {
        width: 100%;
        height: 110px;
        z-index: 99;
        position: fixed;
        background: linear-gradient(180deg, rgba(15, 14, 16, 0.00) 0%, #0F0E10 16.66%);
        bottom: 0;
        left: 0;
        &__wrap {
            padding-top: 14px;
        }
        &__head {
            svg {
                display: block;
            }
        }
        &__footer {
            margin-top: -1px;
            svg {
                display: block;
            }
        }
    }
    &-menu {
        margin-top: 2px;
        gap: 7px;
        &__link {
            width: 62px;
            height: 62px;
            display: block;
            position: relative;
            &.active {
                .mobile-menu__bg {
                    &-default {
                        display: none;
                    }
                    &-active {
                        display: block;
                    }
                }
                .mobile-menu__icon {
                    path {
                        fill: #0F0E10;
                    }
                }
                .mobile-menu__border {
                    &-color {
                        path {
                            fill: #A991FF;
                        }
                    }
                }
            }
        }
        &__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            svg {
                display: block;
            }
        }
        &__bg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            svg {
                display: block;
            }
            &-active {
                display: none;
            }
        }
        &__border {
            width: 100%;
            height: 100%;
            svg {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}