@import "../../../assets/vendor/_config";
@import "../../../assets/vendor/_vars";

.wallet-main {
  margin-top: 16px;
  margin-inline: 3px;

  &__tabbar {
    display: flex;
    gap: 4px;
  }
  &__tab {
    border: 2px solid $black-color;
    width: 100%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &.active {
      background-color: $black-color;
      border-color: $accent-color;
    }
  }
  &__tabIcon {
    height: 19px;
  }
}
