@import "../../../assets/vendor/_config";
@import "../../../assets/vendor/_vars";


.main-input {
    position: relative;
    display: block;

    &__field {
        border: none;
        background: transparent;
        padding: 8px 10px;
        font-size: 18px;
        font-weight: 500;
        position: relative;
        z-index: 1;
        color: $white-color;
        width: 100%;
    
        &::placeholder {
          color: #ffffff80;
        }
    }
    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        fill: $accent-color;
    }

    &__field:disabled + &__bg {
        fill: #87858f;
      }
      &__field.invalid + &__bg {
        fill: #941414;
      }
}

