// header

@import "../../assets/vendor/_config";
@import "../../assets/vendor/_vars";

.header {
  padding-top: 10px;
  &-wrapper {
    height: 86px;
    position: relative;
    padding: 0px 5px;
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-link {
    background: $accent-color;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    z-index: 2;

    svg {
      display: block;
    }
  }

  &-user {
    position: relative;
    z-index: 2;

    &__bg {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        display: block;
      }
    }
    &__avatar {
      width: 42px;
      height: 42px;
      overflow: hidden;
      border-radius: 50%;
    }
  }
  &-resources {
    height: 30px;
    width: 100%;
    position: relative;
    &__bg {
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &-list {
      gap: 12px;
      &__item {
        gap: 4px;
      }
      &__icon {
        width: 19px;
        height: 19px;
        flex-shrink: 0;
        overflow: hidden;
        border-radius: 50%;
      }
      &__text {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
