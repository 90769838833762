@import "../../assets/vendor/vars";

.tutorialPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 20px;
  padding-inline: 12px;

  &__img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 80%;
    max-height: 60vh;
    object-fit: contain;
    animation: imageDisplay 0.7s ease-in 1;
    transition: all 0.3s;
  }

  &__main {
    border: 1px solid #7f5cff;
    background-color: #1c1731;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  &__slider {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    gap: 20px;
  }

  &__main-img {
    max-width: 100%;
    margin-inline: auto;
    margin-bottom: 10px;
    display: block;
    opacity: 0;
    transition: all 1s;
    max-height: 180px;

    &.active {
      opacity: 1;
    }
  }

  &__main-texts-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    justify-content: space-between;
  }

  &__main-text {
    color: $white-color;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    transition: all 0.3s;
    opacity: 0;

    &.active {
      opacity: 1;

      span {
        animation: letterDisplay 0.1s linear;
        animation-fill-mode: backwards;
      }
    }

    &.html {
      display: block;
      color: #ffffffb2;
    }

    & button {
      background: transparent;
      border: none;
      color: $accent-color;
    }
  }

  &__btns-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    z-index: 3;
  }

  &__nav-btn {
    &.hidden {
      width: 0;
      opacity: 0;
    }
  }
}

@keyframes imageDisplay {
  from {
    transform: translate(-100%);
    opacity: 0;
  }
  to {
    transform: translate(0%);
    opacity: 1;
  }
}

@keyframes letterDisplay {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
