@import "../../../assets/vendor/vars";

.hack-terminal-main {
  &__wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 14px;
  }

  &__header {
    background: linear-gradient(91.18deg, #7f5cff 1.86%, #201127 98.14%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-radius: 5px 5px 0 0;
  }

  &__header-title {
    font-family: "handelsonsix";
    color: #df9aff;
    font-size: 20px;
    text-shadow: -1px -1px 0 #1f1f1f, 1px -1px 0 #1f1f1f, -1px 1px 0 #1f1f1f,
      1px 1px 0 #1f1f1f;
  }

  &__close-btn-wrapper {
    flex: 1;
  }

  &__close-btn {
    cursor: pointer;
    background: transparent;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 2px solid $accent-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  &__game-wrapper {
    background: #201127;
    padding: 20px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $accent-color;
    border-radius: 0 0 5px 5px;
  }

  &__game-title {
    font-family: "BOWLER";
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: $accent-color;
  }

  &__atempts-text {
    margin-top: 6px;
    font-family: "HausSquarePro";
    margin-bottom: 15px;
    text-align: center;
    color: $accent-color;
    font-weight: 300;
  }

  &__password-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    max-width: 232px;
    width: 100%;
    margin-inline: auto;
    margin-bottom: 22px;
  }

  &__password-num-btn {
    border-radius: 2px;
    border: 1px solid $accent-color;
    width: 100%;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: transparent;
    font-family: "a_lcdnovaobl";
    font-size: 20px;
    color: $accent-color;
    transition: all 0.3s;

    &:active,
    &.active {
      background-color: $accent-color;
      color: #201127;
    }
  }

  &__check-btn {
    margin-top: 20px;
    max-width: 300px;
    width: 100%;
    background: transparent;
    border: 1px dashed $accent-color;
    border-radius: 2px;
    padding: 9px;
    text-align: center;
    font-family: "a_lcdnovaobl";
    font-size: 16px;
    color: $accent-color;

    &:active {
      border-style: solid;
      background-color: $accent-color;
      color: #201127;
    }

    &:disabled {
      cursor: default;
      filter: grayscale(1);
    }
  }

  &__history {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__history-text {
    font-family: "HausSquarePro";
    font-size: 12px;
    text-align: center;
    font-weight: 300;
    color: $accent-color;
    text-align: center;
    margin-bottom: 5px;
  }

  &__history-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    max-height: 47px;
    min-height: 47px;
    overflow: hidden;
    transition: all 0.3s;

    &.open {
      max-height: 140px;
    }
  }

  &__history-list-item {
    text-align: center;
    letter-spacing: 6px;
    font-family: "a_lcdnovaobl";
  }

  &__toggle-list-btn {
    width: 83px;
    background: transparent;
    border: none;
    border-top: 1px solid $accent-color;
    padding-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 19px;
    transition: all 0.3s;

    &.open svg {
      transform: rotate(180deg);
    }
  }

  &__numpad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 8px;
    width: 100%;
    max-width: 300px;
    overflow: hidden;
    transition: all 0.3s;
    max-height: 160px;

    &.closed {
      max-height: 0;
    }
  }

  &__numpad-btn {
    width: 100%;
    background: transparent;
    border: 1px solid $accent-color;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accent-color;
    font-size: 24px;
    font-family: "a_lcdnovaobl";
    height: 30px;

    & svg {
      fill: $accent-color;
    }

    &:active {
      background-color: $accent-color;
      color: #201127;

      & svg {
        fill: #201127;
      }
    }
  }

  &__options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    gap: 20px;
    width: 100%;
  }

  &__options-btn {
    max-width: 133px;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-family: "HausSquarePro";
    font-weight: 300;
    color: $accent-color;
    border: 1px solid $accent-color;
  }

  &__banner {
    margin-top: 20px;
    width: 100%;
    display: flex;
    height: 300px;
    justify-content: center;
    transition: all 0.3s;

    &.hidden {
      opacity: 0;
    }
  }

  &__text-wrapper {
    padding-inline: 36px;
    padding-top: 22px;
  }

  &__text-content {
    font-family: "HausSquarePro";
    font-size: 14px;
    font-weight: 300;
    color: $accent-color;
    line-height: 21px;
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 6px;

    ul {
      list-style-type: disc;
    }
  }

  &__list-inner {
    padding-left: 30px;
  }

  &__list-item {
    display: list-item;
    padding: initial;
  }

  &__loader {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1f1f1f5d;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: transparent;
      border: 6px solid $accent-color;
      border-top-color: #ffffff00;
      animation: loader 10s linear infinite;
    }
  }

  &__error-text {
    margin-top: 15px;
    font-size: 20px;
    font-family: "HausSquarePro";
    text-align: center;
    color: $accent-color;
    font-weight: 300;
  }
}

@keyframes loader {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 3600deg;
  }
}
