@import "../../../assets/vendor/_vars";

.image-puzzle-modal {
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  padding: 10px;
  border: 1px solid $accent-color;
  background: url("../../../assets/img/popup-bg.jpg") no-repeat center top /
    cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &__title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
  }

  &__bonus {
    text-align: center;
  }
}
