@import "../../../assets/vendor/vars";

.image-puzzle-main {
  display: flex;
  flex-direction: column;
  padding-block: 10px;
  gap: 10px;

  &__loader {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #1f1f1f5d;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: transparent;
      border: 6px solid $accent-color;
      border-top-color: #ffffff00;
      animation: loader 10s linear infinite;
    }
  }

  &__banner {
    padding: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
