@import "../../../assets/vendor/vars";

.friends-rewards {
  margin-top: 48px;

  &__title {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: $accent-color;
    text-transform: uppercase;
  }

  &__list {
    margin-top: 8px;
    row-gap: 8px;
    flex-wrap: wrap;
  }
  &__item {
    padding: 10px;
    background: #1c1632;
    width: 49%;
    text-align: center;

    &.big {
      width: 100%;
    }
  }
  &__item.big &__text {
    margin-top: 0;
  }
  &__item.big &__value {
    width: fit-content;
  }
  &__value {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    z-index: 1;
    position: relative;
    width: 100%;
    &::after {
      content: "";
      display: block;
      width: 40px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(131, 45, 162, 0.5) 0%,
        rgba(131, 45, 162, 0) 100%
      );
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, -50%);
      height: 40px;
    }
  }
  &__icon {
    margin-left: 1px;
    margin-right: 4px;
    width: 28px;
    img {
      display: block;
      width: 100%;
    }
  }
  &__text {
    font-size: 12px;
    font-style: normal;
    margin-top: 5px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
    line-height: normal;
  }
}
