@import "../../../../assets/vendor/vars";

.regular-rask-item {
  &__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    color: $accent-color;
    flex: 1;
  }
  background: #1c1632;
  &__main {
    padding: 11px 10px;
  }

  &__mainImg {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;
  }

  &__text {
    margin-top: 10px;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.8);
  }
  &__offer {
    margin-top: 8px;
    .regular-rask-item__reward {
      min-width: 82px;
      width: fit-content;
      padding-inline: 4px;
    }
  }
  &__btns {
    gap: 8px;
  }

  &__item-timer {
    font-size: 11px;
    font-weight: 400;
    color: #d947ab;
    font-family: "Orbitron", sans-serif;
  }

  .regular-rask-item__btn {
    width: 91px;
    height: 32px;
  }
  &__reward {
    gap: 6px;
    font-size: 12px;
    position: relative;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    z-index: 1;
    padding: 2px 0px;
    img {
      display: block;
      flex-shrink: 0;
      width: 19px;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__btn {
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 100%;
    padding: 10px 0px;
    color: #fff;
    position: relative;
    z-index: 1;
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
