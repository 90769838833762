@import "../../../assets/vendor/vars";

.tasks-tabbar {
  padding: 0px 4px 0px 6px;
  gap: 9px;
  height: 50px;
  position: relative;

  &__bg {
    z-index: -1;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    gap: 4px;
    width: 100%;
  }
  &__item {
    border: 1px solid rgba(127, 92, 255, 0.5);
    background: var(--secondary, $black-color);
    height: 40px;
    padding: 0px 5px;
    font-size: 12px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.82);
    font-weight: 400;
    text-align: center;
    line-height: 13px;
    transition: all 0.4s ease;
    width: 100%;
    &.active {
      background: $accent-color;
      color: #fff;
      font-weight: 600;
    }
  }
}
