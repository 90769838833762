@import '../../../assets/vendor/vars';


.main-stats {
    position: relative;
    height: 109px;
    position: relative;
    margin: 0px 5px;
    padding-top: 12px;
    padding-left: 27px;
    padding-right: 27px;
    &__item {
        position: relative;
        z-index: 1;
        width: 103px;
        padding: 11px 12px 0px 12px;
    }
    &__item-bg {
        position: absolute;
        top: 0;
        z-index: -1;
        left: 0;
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__value {
        bottom: 34px;
        position: absolute;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        left: 50%;
        transform: translateX(-50%);
    }
    &__icon {
        width: 22px;
        height: 22px;
        overflow: hidden;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 5px;
       
    }
    &__title {
        font-size: 9px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.70);
        font-family: 'Raleway', sans-serif;
    }
    &__bg {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}