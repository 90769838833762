// config


.f-center-jcsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
}

.f-jcsb {
    display: flex;
    justify-content: space-between;
}

.f-center {
    display: flex;
    align-items: center;
}
.f-end {
    display: flex;
    justify-content: flex-end;
}

.f-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.f-justify-center {
    display: flex;
    justify-content: center;
}

.cover-image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}