@import "../../../assets/vendor/vars";

.builds-tabBar {
  padding: 0px 4px 0px 6px;
  gap: 9px;
  height: 50px;
  position: relative;
  &::after {
    content: "";
    display: block;
    height: 46px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 99px;
    width: 1px;
    background: $black-color;
  }
  &__bg {
    z-index: -1;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__change {
    width: 91px;
    height: 40px;
    position: relative;
    z-index: 1;
    flex-shrink: 0;
    transform: translateY(-1px);
  }
  &__change-text {
    font-size: 10px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    line-height: 13px;
  }
  &__change-bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    svg {
      display: block;
    }
  }
  &__wrapper {
    gap: 4px;
    width: 100%;
  }
  &__item {
    border: 1px solid rgba(127, 92, 255, 0.5);
    background: var(--secondary, $black-color);
    height: 40px;
    padding: 0px 5px;
    font-size: 12px;
    font-style: normal;
    color: rgba(255, 255, 255, 0.82);
    font-weight: 400;
    text-align: center;
    line-height: 13px;
    transition: all 0.4s ease;
    width: 100%;
    &.active {
      background: $accent-color;
      color: #fff;
      font-weight: 600;
    }
  }
}
