@import "../../../assets/vendor/vars";

.image-puzzle-game {
  &__board {
    padding-inline: 14px;
    padding-bottom: 15px;
    display: grid;
    max-width: 340px;
    margin-inline: auto;
  }
  &__board-item {
    width: 100%;
    aspect-ratio: 1/1;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &.dragover {
      background-color: #ccc;
    }

    &.withBg {
      border: 1px solid #332053;

      &.dragover {
        filter: grayscale(5);
      }
    }

    &.absolute {
      width: 58px;
      height: 58px;
    }
  }

  &__draggable-peace {
    position: absolute;
    z-index: 5;
    transform: translate(-50%, -50%);
  }
  &__pieces-list {
    width: 100%;
    display: flex;
    gap: 22px;
    padding-top: 28px;
    padding-bottom: 23px;
    min-height: 111px;
    background: linear-gradient(
      90deg,
      rgba(125, 57, 255, 0.2) 0%,
      rgba(35, 15, 71, 0.2) 100%
    );
    border: 1px solid #6a4cd1;
    overflow-x: auto;
    padding-inline: 14px;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background: #ff0000;
    }
  }
  &__piece {
    min-width: 58px;
    width: 58px;
    aspect-ratio: 1/1;
    background-color: white;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    gap: 20px;  
    border-bottom: 1px solid #6a4cd1;
    padding: 10px 14px;
  }
  &__footer-btn {
    max-width: 133px;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    font-family: "HausSquarePro";
    font-weight: 300;
    color: $accent-color;
    border: 1px solid $accent-color;
  }
}
