@import "../../assets/vendor/vars";

.app-loader {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $bg-color;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s linear;
  }

  &__loader {
    width: 100%;
    height: 100%;
    position: absolute;
    max-width: 576px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000000c5;
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s linear;

    &::after {
      content: "";
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: transparent;
      border: 6px solid $accent-color;
      border-top-color: #ffffff00;
      animation: loader 10s linear infinite;
    }
  }
  .hidden {
    opacity: 0;
  }

  &__hint {
    z-index: 5;
    position: absolute;
    left: 50%;
    bottom: 25px;
    transform: translateX(-50%);
    padding: 20px;
  }

  &__hint-text {
    text-align: center;
    font-size: 16px;
    color: $white-color;
    font-weight: 500;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    background-color: #7f5cff81;
    animation: initHint 0.3s linear 1;
  }
}

@keyframes initHint {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
