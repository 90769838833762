
.tootlip {
  position: fixed;
  bottom: 10px;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 20px 10px;
  background-color: rgb(42, 30, 84);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 9999;
  text-align: center;

  &_text {
    font-size: 12px;
    text-align: center;
  }
}
