@import "../../../assets/vendor/vars";

.builds-list {
  &__summary_income {
    margin-top: 20px;
    margin-bottom: 8px;
    background-color: rgba(42, 30, 84, 0.5);
    padding: 4px;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    gap: 4;
    flex-wrap: wrap;
    text-align: center;

    & span {
      width: 100%;
    }
    & img {
      width: 20px;
    }
  }
}
