@import "../../../assets/vendor/vars";

.image-puzzle-header {
  padding-inline: 14px;

  &__main {
    background: linear-gradient(91.18deg, #7f5cff 1.86%, #201127 98.14%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    border-radius: 5px 5px 0 0;
  }

  &__player-wrapper {
    flex: 1;
  }

  &__title {
    font-size: 16px;
    font-weight: 400;
    font-family: "square_wood-7";
    color: #df9aff;
    text-shadow: -1px -1px 0 #1f1f1f, 1px -1px 0 #1f1f1f, -1px 1px 0 #1f1f1f,
      1px 1px 0 #1f1f1f;
    margin-top: 4px;
  }

  &__close-btn-wrapper {
    flex: 1;
  }

  &__close-btn {
    cursor: pointer;
    background: transparent;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 2px solid $accent-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
  }

  &__info {
    display: flex;
    justify-content: center;
    gap: 3px;
  }

  &__info-desk {
    flex: 1.5;
    padding: 5px;
    border: 1px solid #6e4ed7;
    color: #7f5cff;
    background-color: #2b1a40;
    font-size: 12px;
    font-weight: 300;
    font-family: "HausSquarePro";

    &.left {
      border-radius: 0 0 5px 0;
    }

    &.right {
      border-radius: 0 0 0 5px;
      text-align: right;
    }
  }

  &__awards-btn {
    border: 1px dashed #7f5cff;
    flex: 1;
    background: transparent;
    padding: 8px;
    border-radius: 0 0 5px 5px;
    font-size: 12px;
    font-weight: 400;
    font-family: "a_lcdnovaobl";
    margin-top: 1px;
    transition: all 0.3s;
    color: #7f5cff;

    &.active {
      border-style: solid;
      background: #7957f1;
      color: #0f0e10;
    }
  }
}
